/* You can add global styles to this file, and also import other style files */
/* @font-face {
  font-family: "Intel-Clear";
  src: url(./assets/fonts/IntelClear_Rg.ttf) format("truetype"),
    url(./assets/fonts/IntelClear_Md.ttf) format("truetype"),
    url(./assets/fonts/IntelClear_Bd.ttf) format("truetype"),
    url(./assets/fonts/IntelClear_Lt.ttf), url(./assets/fonts/IntelClear_Th.ttf);
} */

/* 
@font-face {
  font-family: "IntelOne-Display";
  src: url(./assets/fonts/intelone-display-regular.ttf) format("truetype");
  font-weight: normal;
}
@font-face {
  font-family: "IntelOne-Display";
  src: url(./assets/fonts/intelone-display-medium.ttf) format("truetype");
  font-weight: 500;
} */
/* @font-face {
  font-family: "IntelOne-Display";
  src: url(./assets/fonts/intelone-display-bold.ttf) format("truetype");
  font-weight: bold;
}
@font-face {
  font-family: "IntelOne-Display";
  src: url(./assets/fonts/intelone-display-light.ttf) format("truetype");
  font-weight: 200;
} */

html,body {
  font-size: 16px;
  line-height: 1.5;
  margin: 0px;
  outline: none !important;
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
  /* min-height: 100vh !important;
  height: 100vh;
  max-height: -webkit-fill-available;
  max-width: -moz-available;
  max-width: fill-available;
  flex-grow: 1; */
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
}
/* scrollbar */
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  background: transparent;
  border-radius: 2px;
} 
::-webkit-scrollbar-thumb {
  background: #808080;
  border-radius: 2px;
}
.compare-wrapper ::-webkit-scrollbar-thumb {
  background: #808080 !important;
}
.main_content.chromebook-background ::-webkit-scrollbar-thumb,
.chromebook-background ::-webkit-scrollbar-thumb{
  background: #FFFFFF !important;
  border-radius: 2px;
}
/* Font intel-one-display for en-US */

.intelOne-display {
  font-family: IntelOne-Display, serif !important;
}
/* non intel fonts families are added */
.latin-bold {
  font-weight: 700 !important;
} 

.intel-Clear-font {
  font-family: Intel-Clear, serif !important;
}

body {
  background-image: url(./assets/images/home_connected_new.png);
  background-repeat: no-repeat;
  background-size: cover !important; /* IJR-2759 */
  background-size: 100% 100%; /* IJR-2244 */
}

body.gen13Gaming {  
  background-size: 100% 100% !important; 
}

.small_text_sup_xe {
  /* font-size: 14px; */
  top: -0.25rem !important;
}
.storage-standard {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
}
.storage-preTile {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
}
.optane-game {
  color: #FFFFFF;
  margin-bottom: 2.2vh;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
}

.legal-text-popup .modal-dialog {
  padding: 75px 1rem 0.5rem 1rem;
  width: 100%;
}
.buynow-text-popup .modal-dialog {
  padding-top: 50%;
  margin: 30px;
}
body .nonIntel {
  font-family: Segoe UI, serif  !important;
 }
 /* non intel fonts families are added */
html[lang="th"] body .nonIntel{
  font-family: Tahoma, Arial, Helvetica, sans-serif !important;
}
html[lang="th"] .legalText_nonintel {
  margin-top: 0px !important;
  font-family: Tahoma, Arial, Helvetica, sans-serif !important;
}
html[lang="th"] .thai-font .mobile_header .page_name_nonintel {
  font-family: Tahoma, Arial, Helvetica, sans-serif !important;
}

html[lang="ko"] body .nonIntel{
  font-family:'Malgun Gothic', 'Dotum', Verdana, Tahoma, Arial, Helvetica, sans-serif !important;
}
html[lang="ko"] .legalText_nonintel {
  margin-top: 0px !important;
  font-family:'Malgun Gothic', 'Dotum', Verdana, Tahoma, Arial, Helvetica, sans-serif !important;
}
html[lang="ko"] .thai-font .mobile_header .page_name_nonintel {
  font-family:'Malgun Gothic', 'Dotum', Verdana, Tahoma, Arial, Helvetica, sans-serif !important;
}

html[lang="ja"] body .nonIntel{
  font-family:'Yu Gothic', 'MS PGothic (ＭＳ Ｐゴシック)', 'Hiragino Kaku Gothic ProN (ヒラギノ角ゴ ProN W3)', Tahoma, Arial, Helvetica, sans-serif !important;
}
html[lang="ja"] .legalText_nonintel {
  margin-top: 0px !important;
  font-family:'Yu Gothic', 'MS PGothic (ＭＳ Ｐゴシック)', 'Hiragino Kaku Gothic ProN (ヒラギノ角ゴ ProN W3)', Tahoma, Arial, Helvetica, sans-serif !important;
}
html[lang="ja"] .thai-font .mobile_header .page_name_nonintel {
  font-family:'Yu Gothic', 'MS PGothic (ＭＳ Ｐゴシック)', 'Hiragino Kaku Gothic ProN (ヒラギノ角ゴ ProN W3)', Tahoma, Arial, Helvetica, sans-serif !important;
}

html[lang="zh-cn"] body .nonIntel{
  font-family: 'Microsoft YaHei', 'SimHei', Tahoma, Arial, Helvetica, sans-serif !important;
}
html[lang="zh-cn"] .legalText_nonintel {
  margin-top: 0px !important;
  font-family: 'Microsoft YaHei', 'SimHei', Tahoma, Arial, Helvetica, sans-serif !important;
}
html[lang="zh-cn"] .thai-font .mobile_header .page_name_nonintel {
  font-family: 'Microsoft YaHei', 'SimHei', Tahoma, Arial, Helvetica, sans-serif !important;
}

html[lang="zh-tw"] body .nonIntel{
  font-family: 'Microsoft JhengHei', Tahoma, Arial, Helvetica, Verdana, sans-serif !important
}
html[lang="zh-tw"] .legalText_nonintel {
  margin-top: 0px !important;
  font-family: 'Microsoft JhengHei', Tahoma, Arial, Helvetica, Verdana, sans-serif !important
}
html[lang="zh-tw"] .thai-font .mobile_header .page_name_nonintel {
  font-family: 'Microsoft JhengHei', Tahoma, Arial, Helvetica, Verdana, sans-serif !important
}

 .connectivity-paragraph-style ul {
  padding-inline-start: 1rem;
}
.connectivity-paragraph-style li {
  font-size: 16px;
  line-height: 24px;
  margin: 28px 0px;
}
.badgetitle_font{
  font-weight: bold;
  font-size: 20px;
}
.intelOptanMemoryTitle {
  /* font-family: IntelOne-Display, serif; */
  font-style: normal;
  /* font-weight: 500; */
  font-size: 32px;
  line-height: 36px;
  display: flex;
  align-items: center;
}
/* GLS-297205-start*/
.intelOptanMemoryTitle-bold {
font-weight: 500;
}
/* GLS-297205-end*/
/* .storage-seperator{
  display: block;
  max-height: 25px;
} */
/* GLS-301419 */
.nonIntel {
  font-family: Segoe UI, serif  !important; 
}

.ftag-info-wrapper .info-detailed .feature-row .reduce-pad{
  padding: 8px !important;
}
 /* Bug fix IJR-958 */
 /* .processor-wrapper .table-panel .compare_header_table {
  margin-bottom: 10px;
}   */
.processor-wrapper .table-panel .compare_header_table tbody td {
  padding: 0px;
  width: 33.33%;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  /* font-family: IntelOne-Display, serif; */

}
.gaming .compare_header_table tbody td {
  color: #FFFFFF;
}
.info-detailed .feature-row .black-box.altered-bb{
  min-width: 120px !important;
  height: 120px !important;
  padding: 8px !important;
}

/* .processor-wrapper .info-detailed .black-box, .processor-wrapper .info-detailed .black-box .subtitle_font{
  word-break: break-word;
  hyphens: auto;
} */
/* .proc-tile .tile-desc .subtitle, .card-columns .card .card-footer span:first-child{
  word-break: break-word;
  hyphens: auto;
} */
/* .table-panel .compare_chart .compare_table .figure-caption{
  word-break: break-word !important;
} */

/* .processor-wrapper .table-panel .compare_chart .proc-title,
.processor-wrapper .table-panel .compare_chart td span{
  word-break: break-word; 
} */
.style_badge{
  font-weight: bold;
}

 .ftag-info-wrapper .info-detailed .type-section-wrapper{
  width: 100%;
}
.ftag-info-wrapper .info-detailed .type-section-wrapper .type-section{
  /* word-break: break-word; */
  width: 100% !important;
}
html[lang="ko"] .ftag-info-wrapper .info-detailed .type-section-wrapper .type-section{
  word-break: keep-all;
  width: 100% !important;
}
.ftag-info-wrapper .info-detailed .type-section-wrapper .type-section .type,
.ftag-info-wrapper .info-detailed .type-section-wrapper .type-section .description{
 width: 100% !important;
 padding-inline: 4px;
}
.ftag-info-wrapper .info-detailed .type-section-wrapper .type-section .type .altered-type-width,
.ftag-info-wrapper .info-detailed .type-section-wrapper .type-section .description .altered-type-width{
 width: 100% !important;
 padding-inline: 4px;
}
.ftag-info-wrapper .info-detailed .type-section-wrapper > div{
  min-width: 30.33%;
  width: 30.33%;
}
.ftag-info-wrapper .info-detailed .altered-width-connectivity > div{
  min-width: 33%;
  width: 33%;
}
.ftag-info-wrapper .info-detailed .altered-width > div{
  min-width: 30.33%;
  width: 30.33%;
}
.gaming  .feature-row .black-box .value {
  /* font-family: IntelOne-Display, serif; */
  font-size: 1.25rem;
  font-weight: bold;
  line-height: 1.75rem;
  display: block;
}
.subtitle-value {
  font-size: 14px;  /*GLS-290604*/
  font-weight: normal;
  line-height: 20px;  /*GLS-301419*/
  display: block;
  /*font-family: Intel-Clear-Latin, Intel-Clear, serif, IntelOne-Display !important;*/
}
html[lang="en-us"] .subtitle-value {
  font-size: 18px;

}

.gaming-black-box .value {
  font-size: 18px;  /*GLS-290604*/
}

html[lang="ja"] .processor-wrapper .info-detailed .black-box .subtitle_font {
  /* word-break: keep-all; */
}
/*Added below for Ukaraine lang storage HDD & SSD second badge alignmentssue */
.altered-secondbb .value .storage-seperator{
  max-height: fit-content;
}
.detail_processor .info-detailed .subheader {
  padding: 0px 10px;
}

html[lang="ar-sa"] .detail_graphics .gaming .info-detailed .type-value1 {
  top: 35% !important;
}
html[lang="th"] body{
  line-height: 2rem !important;
}
html[lang="th"] .info-detailed .img-info, html[lang="th"] .compare-detailed .img-info{
  line-height: 24px !important;
}
html[lang="th"] .gaming .feature-row + p{
  line-height: 30px !important;
}html[lang="th"] .processor-wrapper .info-detailed .black-box, html[lang="th"] .processor-wrapper .info-detailed .black-box .subtitle_font{
  line-height: 24px !important;
}
html[lang="th"] .paragraph-style{
  line-height: 30px !important;
}html[lang="th"] .gaming .info-detailed > .subtitle{
  line-height: 24px !important;
}
html[lang="th"] .gaming-heading{
  line-height: 50px !important;  /* GLS-298921 */
}
html[lang="th"] .card-holder .spec-holder{
  line-height: 28px !important;
}

html[lang="th"] .info-detailed h2{
  line-height: 44px !important;
}
/* GLS 425677 */
html[lang="th"] .panel .panel-footer .accordion .card div {
  line-height: 26px !important;
}

html[lang="ko"] .koreanWordBreak {
  word-break: keep-all;
}

/* GLS fix for 298457 and 301304 starts */
.gaming .info-detailed .type-section-wrapper.altered-width-connectivity div:not(:first-child) .type-section{
  margin-left: 2.7%;  
}

html[lang="ko"] .gaming .info-detailed .type-section-wrapper.altered-width-connectivity div:not(:first-child) .type-section{
  margin-left: 1.5%;  
}

html[lang="ja"] .gaming .info-detailed .type-section-wrapper.altered-width-connectivity div:not(:first-child) .type-section{
  margin-left: 2.6%;  
}

[dir=rtl] .gaming .info-detailed .type-section-wrapper.altered-width-connectivity div:not(:first-child) .type-section{
  margin-right: 2.7%;  
}

.ftag-info-wrapper .info-detailed .type-section-wrapper.altered-width-connectivity .type-section{
  width: 97% !important;
}
/* GLS fix for 298457 and 301304 ends */

/* Story IJR-19, 66 changes --starts */
.processor-wrapper .info-detailed .feature-row.h45-non-gaming-feature-row .black-box{
	background-color: #FFFFFF;
  border: 2px solid #0068B5;
  color: #0068B5;
  margin-top: 14px;
  margin-bottom: 14px;
  margin-left: 7px;
  max-width: 180px !important;
  padding: 7px;  
  font-size: 14px;
  line-height: 18px;
  text-align: center;  
  margin-right: 7px
}

.info-detailed .feature-row.h45-non-gaming-feature-row{
	flex-flow: row wrap;
}

.h45-non-gaming-p{
	font-size: 20px;
  line-height: 24px;
}

/* Story IJR-19, 66 changes --end */

/* UAT-242 */
@media(max-width: 768px){
  @-moz-document url-prefix() {
      body{
          overflow-y: auto;
      }
  }
}

/* 9GenGaming mobile stories: IJR-154 Epic --starts*/
.tr_subtitle_font {
  color: #FFFFFF;
}

.i9Gaming-text-align {
  text-align: center;
}
/* 9GenGaming mobile stories: IJR-154 Epic --ends*/

.card-footer {
  background: #FFFFFF !important;
}
.big-blue-text {
  font-family: IntelOne-Display, serif;
  font-weight: 500;
  font-size: 32px;
  line-height: 36px;
  display: block;
  color: #0068B5;
}

.processor-wrapper .info-detailed .feature-row.creator-feature-row .black-box{
	background-color: 262626;
  border: 2px solid #EDB200;
  color: #EDB200;  
  max-width: 180px !important;
  padding: 0px;  
  font-size: 14px;
  line-height: 22px;
  text-align: center;  
  margin-right: 8px;
  margin-left: 8px;
  margin-top: 14px;
  margin-bottom: 14px;  
}

.info-detailed .feature-row.creator-feature-row{
	flex-flow: row wrap;
  margin-inline: -8px;
  margin-bottom: -12px !important;
}


.chrome-book ul {
  padding-inline-start: 27px !important;
}

.chrome-book .data sup {
  font-size: 12px;
  top: -0.4em !important;
}
.altered-wifi-font-weight {
  font-weight: 700 !important;
}
.slick-dots li.slick-active button:before {
  color: #E9E9E9 !important;
  opacity: 1 !important;
}
.intelInsideCarousel .slick-dots li.slick-active button:before {
  color: #808080 !important;
  opacity: 0.3 !important;
} 
.slick-dots li button:before {
  color: #808080 !important;
  opacity: 1 !important;
  font-size: 5px !important;
}
.slick-dots li {
  height: 10px !important;
  width: 10px !important;
  margin: 0 2px !important;
}
.fact-tag-listout-padding{
  margin-top: 9.5vh;  /* chat bot home style fix */
}
.fact-tag-listout-aiIpos-padding{
  margin-top: 2.5vh;
}
/* @media only screen and (min-width: 380px){
  .fact-tag-listout-padding {
  margin-top: 9.5vh;
  }
  }
  @media only screen and(min-height: 740px){
  .fact-tag-listout-padding {
  margin-top: 9.5vh;
  }
  } */

.info-detailed div > img,
.compare-wrapper .info-detailed div > img {
  height: 100px;
  width: 100px;
}

.core-value {
  font-style: normal;
  font-weight: bold; 
  font-size: 1.25rem; 
  line-height: 28px; 
  color: #9BF89D;
}

.core-text {
  font-size: 0.875rem; 
  font-weight: 400;
}

/* Gaming second panel style start */
.memory-value {
  color: #00A3F6;
  /* font-family: "IntelOne-Display"; */
  font-style: normal;
  font-weight: normal;
  font-size: 1.5rem;
  line-height: 1.5rem;
  display: flex;
  align-items: center;
  text-align: center;
  margin: 2.49vh 1vw 2.56vh 0.33vw;
  flex-direction: column;
}
.ram_value {
  font-size: 16px;
  margin-top: 4px;
}

/* Fix IIT2-4325 */
html[lang="is"] .memory-icon .memory-value .ram_value{
  font-size: 0.79rem !important;
}

/* Gaming second panel style end */

/* chatbot horizontal scroll fix */
.koreanWordBreak .horizontal-scroll {
  overflow-x: hidden !important;
  overscroll-behavior: none !important;
}
.creator-info-detailed {
  background-color: #262626 !important ;
  color: #FFFFFF !important;
  padding: 24px !important;
}

.std12_text {
  color: #00285A !important;  /* IJR-2920 */
}
.gaming-background {
  background: #262626 !important;
}
.standard-background {
  background: #FFFFFF !important;
}
.std-font-color {
  color: #262626 !important;
}
.gaming-font-color {
  color: #FFFFFF !important;
}
.black-label-color {
  color: #262626 !important;
}
/* IIT2-2546 */
.sup-text {
  top: -0.75rem !important;
  font-size: 50% !important;
  font-weight: bold;
}
/* GLS - 402870 */
html[lang="en-us"] .sup-symbol {
  top: -0.2rem !important;
  font-size: 80% !important;
}
/* IIT2-2546 */
:focus {
  outline: 0 !important;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
}

/* GLS - 420374 */
.clear-font-bold {
  font-weight: 700 !important;
}
/* oled */
.conventional_text {
  font-weight: 500 !important;
  font-size: 9px !important;
}

.samsungoled_text {
  font-weight: 500 !important;
  font-size: 9px !important;
}

/* GLS- 508615 */
html[lang="th"] .rebuild-panel h5 {
  line-height: 44px !important;
  padding-bottom: unset !important;
}

/* 13th gen std core value color issue */

.gaming13thgen_coreValue .core-value, .gaming13thgen_coreValue .core-text {
  background: linear-gradient(90.21deg, #5AE9FE 0.21%, #4861F8 99.85%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.std13thgen_text {
  color: #262626 !important;
}

@media (min-width: 321px) and (max-width: 375px) {
  .hungary-arc-heading {
    font-size: 28px !important;
  }
}

@media (max-width: 320px) {
  .hungary-arc-heading {
    font-size: 25px !important;
  }

  html[lang="el"] .ht_header_even {
    max-width: 43.556vw !important;
  }
}

@media (max-width: 360px) {
  html[lang="el"] .ht_header_even {
    max-width: 42.556vw !important;
  }

  html[lang="is"] .ht_header_even {
    max-width: 44.556vw !important;
  }

  html[lang="hu"] .hu-lang-title,
  html[lang="uk"] .rebuild-panel h5 {
    font-size: 30px !important
  }

  html[lang="ru"] .rebuild-panel h5,
  html[lang="ru"] .ru-lang-title {
    font-size: 28px !important;
  }

  html[lang="uk"], html[lang="el"], html[lang="id"], html[lang="fr"], html[lang="hu"], html[lang="vi"] { 
    #secondPanelBadgeIconTile.panel_subtitle
      {
        font-size: 13px !important;
      } 
  }
}

.marklist {
  color: #0068B5 !important;
}

@media (min-width: 320px) and (max-width: 375px) {
  html[lang="de"], html[lang="nl"]  {
    .ht_header_panel-text {
      font-size: 11px !important;
    }
  }
}


.gaming13thgen_coreValue .core-value {
  font-size: 32px !important;
}
.gaming13thgen_coreValue .core-text {
  font-size: 20px !important;
  font-weight: 600 !important;
}

.legal-text-popup.modal {
  display: flex !important;
  align-items: flex-start !important;
  justify-content: center;
}    

.expandable_heading{
  font-weight: 700;
  font-size: 14px;
}

.spec-heading{
  font-weight: 700;
  font-size: 18px;  
}